import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from "../components/seo"
import { Box, Text, Grid, } from "theme-ui";

const ThoughtsPage = ({ data }) => (
  <Layout>
    <SEO title={'👋'} />
    <Grid gap={[1, 2, 3, 4, 5]} columns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}>
      {data.allStrapiThought.edges.map(document => (
        <Box
          px={0} py={2} key={document.node.slug}>
          <Link to={`/thoughts/${document.node.slug}`}>
            <Img css={{ borderRadius: '8px' }} fluid={document.node.cover.childImageSharp.fluid} />
            <Text sx={{ display: 'inline' }}>{document.node.title} / </Text>
            <Text sx={{ display: 'inline' }}>{document.node.date}</Text>
            {/* <ReactMarkdown 
             source={document.node.description}
             transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
          /> */}
          </Link>
        </Box>
      ))}
    </Grid>
  </Layout>
)

export default ThoughtsPage

export const pageQuery = graphql`  
  query ThoughtsPageQuery {
    site {
      siteMetadata {
        author
      }
    }
    allStrapiThought (sort: {fields: date, order: DESC}) {
      edges {
        node {
          id
          slug
          title
          description
          date
          cover {
            childImageSharp {
              fluid(maxWidth: 600 maxHeight: 400 cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`